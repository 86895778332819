import styled from 'styled-components'

type VideoTitleProps = {
  title: string
  description: string | undefined
  league: string | undefined
  date: Date | undefined
}

const TitleWrapper = styled.div`
  background-color: var(--canvas);
  border-radius: 0px;
  padding: 8px 4px 8px 4px;
  gap: 8px;
  color: var(--on-background);
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
`

const MainTitle = styled.h3`
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0;
`

const SubTitle = styled.p`
  margin: 0;
  color: var(--on-background-alt);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  white-space: nowrap;
  max-width: 1080px;
  overflow: hidden;
  text-overflow: ellipsis;
`
const SubTitleWrapper = styled.div`
  display: flex;
`

const VideoTitle = (props: VideoTitleProps) => {
  const { title, description, league, date } = props
  let subtitleDate
  if (date) {
    const dateTime = new Date(date)
    const [dtmonth, , dtday] = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).formatToParts(
      dateTime
    )
    subtitleDate = `${dtmonth.value.toUpperCase()} ${dtday.value}`
  }
  return (
    <TitleWrapper>
      <SubTitleWrapper>
        {league && <SubTitle style={{ marginRight: 10 }}>{league}</SubTitle>}
        {subtitleDate && <SubTitle>{subtitleDate}</SubTitle>} {/* Conditional rendering */}
      </SubTitleWrapper>
      <MainTitle>{title}</MainTitle>
      <SubTitle>{description}</SubTitle>
    </TitleWrapper>
  )
}

export default VideoTitle
