import { getWindowDeviceInfo } from '@grandstand-web/bally-web-shared/src/utils/getWindowDeviceInfo'
import { Logger } from '@grandstand-web/bally-web-shared/src/utils/logger'
import getConfig from 'next/config'
const logger = Logger.of('modifyDRMTokenIfApplicable')

/**
 * LOCAL XBOX DRM:
 * This is intended to be used for xboxes in devMode
 */

export interface CrtTemplates {
  stage: string
  prod: string
}
export interface LocalXboxDrm {
  templates: CrtTemplates
  key: string
}
export interface LocalXboxDrmRequest {
  template: string
  key: string
  token: string
}
export interface LocalXboxDrmResponse {
  token: string
}
export const modifyDRMTokenIfApplicable = async (token: string) => {
  const drm = getLocalXboxDrm()
  const isXbox = getWindowDeviceInfo().platform === 'tv_xboxone'
  if (!drm || !isXbox) {
    return token
  }
  const { publicRuntimeConfig } = getConfig()
  const isStage = publicRuntimeConfig?.environment === '6'
  const { templates, key } = drm
  const template = isStage ? templates.stage : templates.prod
  try {
    const response = await fetch('/api/drm', {
      method: 'POST',
      body: JSON.stringify({ token, key, template }),
    })
    if (!response.ok) {
      const error = await response.json()
      logger.warn('Error handling local drm orchestration for xbox', {
        error,
      })
      return token
    }
    const data = (await response.json()) as { token: string }
    return data.token ?? token
  } catch (error) {
    logger.warn('Error handling local drm orchestration for xbox', {
      error,
    })
    return token
  }
}

function getLocalXboxDrm(): LocalXboxDrm | undefined {
  const { publicRuntimeConfig } = getConfig()
  if (publicRuntimeConfig?.localXboxDrm) {
    return publicRuntimeConfig.localXboxDrm
  }
  try {
    if (typeof window?.localStorage === 'undefined') {
      return undefined
    } else {
      const value = window.localStorage.getItem('localXboxDrm')
      return value ? (JSON.parse(value) as LocalXboxDrm) : undefined
    }
  } catch (_) {
    return undefined
  }
}
