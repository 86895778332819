import { getIsDebug } from '@grandstand-web/bally-web-shared/src/hooks/useIsDebug'
import { ApiClient } from '@grandstand-web/bally-web-shared/src/newPackages/ApiClient'
import {
  ConfigServiceContext,
  useContextUnconditionally,
} from '@grandstand-web/bally-web-shared/src/services/config/ConfigService'
import { UserServiceContext } from '@grandstand-web/bally-web-shared/src/services/user/UserService'
import { ErrorDetails } from '@grandstand-web/bally-web-shared/src/type/Error'
import { isConnectedWeb } from '@grandstand-web/bally-web-shared/src/utils/appUtils'
import { getWindowDeviceInfo } from '@grandstand-web/bally-web-shared/src/utils/getWindowDeviceInfo'
import { Logger } from '@grandstand-web/bally-web-shared/src/utils/logger'
import { TDeviceInfo } from '@grandstand-web/device-info/src/types'
import { useContext, useEffect, useMemo, useState } from 'react'
import { getAppVersion } from '../../utils/deviceInfoUtils'
import { DataStatus, TVideoResponse, VideoAnalyticsType, VideoResponse, VideoServiceProps } from './types'

export function useVideoResponse(props: VideoServiceProps): TVideoResponse {
  const { videoId, isDisabled, playerType } = props

  const { currentConfig } = useContextUnconditionally(ConfigServiceContext)
  const { currentUser } = useContext(UserServiceContext)
  const [error, setError] = useState<ErrorDetails | null>(null)
  const [data, setData] = useState<VideoResponse | null>(null)
  const [analytics, setAnalytics] = useState<VideoAnalyticsType>({ default: {}, conviva: {} })
  const [deviceInfo, setDeviceInfo] = useState<TDeviceInfo>(getWindowDeviceInfo())
  const isXbox = deviceInfo.platform === 'tv_xboxone'
  const isTizen = deviceInfo.platform === 'tv_samsung'
  const isCW = isXbox || isTizen
  const isSafari = !isCW && deviceInfo.browser === 'safari'
  const adInfo = deviceInfo.adInfo
  const drmInfo = deviceInfo?.drmInfo

  const status = useMemo<DataStatus>(() => {
    if (error) {
      return 'error'
    }
    if (data) {
      return 'success'
    }
    return 'loading'
  }, [error, data])

  // keep deviceInfo up to date
  useEffect(() => {
    const updateDeviceInfo = () => {
      setDeviceInfo(getWindowDeviceInfo())
    }
    updateDeviceInfo()
  }, [])

  useEffect(() => {
    if (error || typeof window === 'undefined' || !drmInfo) {
      return
    }
    const getVideo = async () => {
      Logger.of('useVideoResponse.getVideo').info('Getting video', { videoId, deviceInfo })

      const configUrl = currentConfig?.API.services.video_services.playback
      const token = currentUser?.user_token

      if (configUrl === undefined || token === undefined || !drmInfo || !adInfo) {
        return
      }

      const getUrl = (): string => {
        const baseUrl = `${configUrl}/${videoId}`
        const params: { [key: string]: any } = {
          drmType: drmInfo.type,
          format: drmInfo.format,
          appversion: getAppVersion(),
          debug: getIsDebug(),
          ...deviceInfo.adInfo,
        }

        const favTeams = currentUser?.profile.favorites.teams

        if (favTeams && favTeams.length > 0) {
          params.favteams = favTeams.join(',')
        }

        if (isConnectedWeb()) {
          // this controls the length of live streams' dvr windows
          // if true: the player will only load 2 min of dvr window
          // else: the player will load the full dvr window (which can be up to 4 hours, slowing down load times)
          params.chromecast = true
        }
        if (params.did === '') {
          delete params.did
        }

        // convert params to string
        const paramsString = new URLSearchParams(params).toString()

        const url = `${baseUrl}?${paramsString}`
        Logger.of('useVideoResponse.getVideo').debug('params', { params, paramsString, url, videoId })
        return url
      }
      const url = getUrl()
      try {
        const res = await ApiClient.convenientApiFetch({
          url,
          method: 'GET',
        })

        if (res.ok) {
          const data = (await res.json()) as VideoResponse
          setAnalytics({
            default: data?.analytics ?? {},
            conviva: data?.conviva_analytics ?? {},
          })
          setData(data)
          setError(null)
        } else {
          const error = await res.json()
          Logger.of('useVideoResponse.getVideo').error('Video service error', { videoId, error })
          setError(error)
          setAnalytics({ default: {}, conviva: {} })
          setData(null)
        }
      } catch (error) {
        Logger.of('useVideoResponse.getVideo').error('Video fetch error', { error, url, videoId })
        setError({ status: 0, code: 'network_error', title: 'network error', message: 'network error', severity: 0 })
        setAnalytics({ default: {}, conviva: {} })
        setData(null)
      }
    }

    // get video
    getVideo()
    return () => {
      /* */
    }
  }, [
    playerType,
    adInfo,
    currentConfig?.API.services.video_services.playback,
    currentUser?.profile.favorites.teams,
    currentUser?.user_token,
    deviceInfo,
    deviceInfo.adInfo,
    drmInfo,
    error,
    isSafari,
    videoId,
  ])

  useEffect(() => {
    const heartbeat = data?.heart_beat
    if (error || !heartbeat || isDisabled) {
      return () => {
        /* */
      }
    }
    const url = heartbeat.link.url

    const getHeartbeat = async () => {
      if (error) {
        return
      }
      try {
        const res = await ApiClient.convenientApiFetch({
          method: 'POST',
          url,
        })
        if (res.ok) {
          // Heartbeat successful!
          return
        }
        const error = await res.json()
        /**
         * We only want to display these errors. See ...
         * - Slack Thread with Joe: https://hg.slack.com/archives/C04LY14GZN3/p1728319422542739
         * - Confluence Docs: https://ballysports.atlassian.net/wiki/spaces/EN/pages/319651848/Concurrency+Enforcement+Implementation
         * - Swagger: https://middleware.stage.gs.ballysports.com/docs/#/Video/post_video_heartbeat
         */
        const errorsWeCareAbout = ['concurrency_limit_reached', 'unauthorized_device_id', 'video_not_available']
        if (errorsWeCareAbout.includes(error.code)) {
          Logger.of('useVideoService.getHeartbeat').error('Video service error', { videoId, error })
          setError({
            code: 'unknown_heartbeat_error', // will be overwritten if code present
            ...error,
          })
        }
        return
      } catch (e) {
        console.error(e)
      }
    }

    getHeartbeat()

    // get heartbeat every ms
    const ms = heartbeat?.interval ?? 500
    const heartbeatInterval = setInterval(() => {
      getHeartbeat()
    }, ms)
    return () => {
      clearInterval(heartbeatInterval)
    }
  }, [error, videoId, data?.heart_beat, isDisabled])

  return {
    analytics,
    data,
    error,
    setError,
    status,
    playerType,
  }
}
