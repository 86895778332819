import Image from 'next/image'
import styled from 'styled-components'
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Img = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
`
export const PlayerUpcoming = ({ image }: { image?: string }) => {
  const src = image || '/fallback-thumbnail.png'
  return (
    <Wrapper>
      <Img src={src} alt="" sizes="1398px" fill priority loading="eager" decoding="sync" />
    </Wrapper>
  )
}
