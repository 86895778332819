import { isConnectedWeb } from '@grandstand-web/bally-web-shared/src/utils/appUtils'
import { isNotInProd } from '@grandstand-web/bally-web-shared/src/utils/envUtils'
import { PropsWithChildren, useEffect, useState } from 'react'
import styled from 'styled-components'
import { PlayerSwitcher } from './PlayerSwitcher'

const Wrapper = styled.div<{ showVideoAtFullScreenWidth: boolean }>`
  aspect-ratio: 16 / 9;
  ${({ showVideoAtFullScreenWidth }) => {
    if (showVideoAtFullScreenWidth) {
      return 'width: 100vw; height: 56.25vw;'
    }
    return 'width: 177.78vh; height: 100vh; margin-left: auto; margin-right: auto;'
  }};
`

const VideoPlayerWrapper = (props: PropsWithChildren) => {
  const [showVideoAtFullScreenWidth, setShowVideoAtFullScreenWidth] = useState(true)
  useEffect(() => {
    const handleResize = () => {
      // default to window.screen in the unlikely event that visualViewport is not supported
      const viewport = window.visualViewport || window.screen
      const canShowVideoAtFullScreenWidth = viewport.width / viewport.height <= 16 / 9
      setShowVideoAtFullScreenWidth(canShowVideoAtFullScreenWidth)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const showPlayerSwitcher = !isConnectedWeb() && isNotInProd()

  return (
    <Wrapper showVideoAtFullScreenWidth={showVideoAtFullScreenWidth}>
      {showPlayerSwitcher && <PlayerSwitcher />}
      {props.children}
    </Wrapper>
  )
}

export default VideoPlayerWrapper
