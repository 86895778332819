import { clientRuntimeConfig } from '@grandstand-web/bally-web-shared/src/config'
import { isConnectedWeb } from '@grandstand-web/bally-web-shared/src/utils/appUtils'
import { getWindowDeviceInfo } from '@grandstand-web/bally-web-shared/src/utils/getWindowDeviceInfo'
import getConfig from 'next/config'

const getValidString = (str: any): string | undefined => {
  return typeof str === 'string' && str.length > 0 ? str : undefined
}
export const getAppVersion = (ignoreNative?: boolean): string => {
  const { publicRuntimeConfig } = getConfig()
  const rootVersion =
    getValidString(clientRuntimeConfig?.rootVersion) ?? getValidString(publicRuntimeConfig?.version) ?? 'undefined'

  if (ignoreNative) {
    return rootVersion
  }

  return getWindowDeviceInfo()?.nativeVersion ?? rootVersion
}

export const getIsCW = (): boolean => {
  try {
    return isConnectedWeb()
  } catch (_) {
    return false
  }
}

export const getIsSafari = (): boolean => {
  try {
    if (isConnectedWeb()) {
      return false
    }
    const deviceInfo = getWindowDeviceInfo()
    return deviceInfo.browser === 'safari'
  } catch (_) {
    return false
  }
}

export const getIsTizen = (): boolean => {
  try {
    const deviceInfo = getWindowDeviceInfo()
    if (!isConnectedWeb()) {
      return false
    }
    return deviceInfo.platform === 'tv_samsung'
  } catch (_) {
    return false
  }
}

export const getIsXbox = (): boolean => {
  try {
    const deviceInfo = getWindowDeviceInfo()
    if (!isConnectedWeb()) {
      return false
    }
    return deviceInfo.platform === 'tv_xboxone'
  } catch (_) {
    return false
  }
}

export const isMobileOrTablet = () => {
  const deviceInfo = getWindowDeviceInfo()
  return deviceInfo.formFactor === 'tablet' || deviceInfo.formFactor === 'mobile'
}
