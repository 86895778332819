import { useEffect, useState } from 'react'
import { PlayerType } from '../../types'
import { getIsSafari } from '../../utils/deviceInfoUtils'
import { getKeySystems } from '../../utils/getKeySystems'
import { TShakaVideoService, VideoServiceProps, VideoSrc } from './types'
import { useVideoResponse } from './useVideoResponse'

export const useVideoServiceShaka = (props: VideoServiceProps): TShakaVideoService => {
  const res = useVideoResponse(props)
  const { videoId } = props
  const { data } = res
  const [src, setSrc] = useState<VideoSrc | null>(null)

  useEffect(() => {
    const getSrc = async () => {
      if (!data) {
        return null
      }
      const {
        video: { title },
      } = data

      const isSafari = getIsSafari()
      const keySystems = await getKeySystems(data)
      // formats are DASH or hls_v3
      const src: VideoSrc = {
        videoId,
        raw: data,
        title: title,
        type: isSafari ? 'application/x-mpegURL' : 'application/dash+xml',
        src: data.url,
        keySystems: keySystems,
        heartbeat: data.heart_beat,
        analytics: data.analytics,
        conviva_analytics: data?.conviva_analytics ?? {},
        isLive: data.islive,
        stream_type: data.stream_type,
      }

      return src
    }
    getSrc().then(setSrc)
  }, [data, videoId])

  const service: TShakaVideoService = {
    ...res,
    playerType: PlayerType.Shaka,
    src,
  }
  return service
}
