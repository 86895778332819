import styled from 'styled-components'

const LoadingSpinner = styled.div<{ size?: string }>`
  border: 10px solid var(--surface);
  border-top: 10px solid var(--divider-canvas);
  border-radius: 50%;
  width: ${(props) => (props.size ? props.size : '80px')};
  height: ${(props) => (props.size ? props.size : '80px')};
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const LoadingSpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoadingSpinnerOverLay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 50;
  top: 0;
  left: 0;
`

const LoadingSpinnerBackground = styled.div<{ isBuffering?: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--canvas);
  opacity: ${({ isBuffering }) => (isBuffering ? 0.5 : 1)};
`

export const VideoLoading = (props: { isBuffering?: boolean }) => {
  return (
    <LoadingSpinnerOverLay>
      <LoadingSpinnerBackground isBuffering={props.isBuffering} />
      <LoadingSpinnerWrapper>
        <LoadingSpinner />
      </LoadingSpinnerWrapper>
    </LoadingSpinnerOverLay>
  )
}

export default VideoLoading
