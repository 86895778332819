import { GrandstandButton } from '@grandstand-web/bally-web-shared/src/components/Buttons'
import styled from 'styled-components'
import { usePlayerShared } from '../context/PlayerSharedContext'
import { PlayerType } from '../types'
const Container = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  overflow: visible;
`
const Row = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  padding: 16px 20px;
  display: flex;
  justify-content: flex-end;
`
export const PlayerSwitcher = () => {
  const { setPlayerType, playerType } = usePlayerShared()

  const nextPlayer = playerType === PlayerType.Shaka ? 'BITMOVIN' : 'SHAKA'
  const handleClick = () => {
    setPlayerType((prev) => {
      if (prev === PlayerType.Shaka) {
        return PlayerType.Bitmovin
      }
      return PlayerType.Shaka
    })
  }
  return (
    <Container>
      <Row>
        <div>
          <GrandstandButton buttonSize="sm" buttonStyle="primary" onClick={handleClick}>
            SWITCH TO {nextPlayer} PLAYER
          </GrandstandButton>
        </div>
      </Row>
    </Container>
  )
}
