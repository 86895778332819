// REMOVE_SHAKA: FILE
import dynamic from 'next/dynamic'
import VideoLoadingOrBuffering from '../VideoLoadingOrBuffering'

const PlayerShakaDynamic = dynamic(import('./PlayerShakaStatic'), {
  ssr: false,
  loading: () => <VideoLoadingOrBuffering />,
})

export default PlayerShakaDynamic
