import { GrandstandButton } from '@grandstand-web/bally-web-shared/src/components/Buttons'
import useUserError from '@grandstand-web/bally-web-shared/src/hooks/useUserError'
import { breakpoints } from '@grandstand-web/bally-web-shared/src/styles/breakpoints'
import { HeadingLabel, SubheadingLabel } from '@grandstand-web/bally-web-shared/src/styles/fonts'
import { ErrorDetails } from '@grandstand-web/bally-web-shared/src/type/Error'
import styled from 'styled-components'
export type PlaybackState = 'loading' | 'playing' | 'paused'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /* max-height: 1080px; */
  display: flex;
  align-items: center;
  justify-content: center;
`

const Overlay = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
`

const Foreground = styled.div`
  position: relative;
  z-index: 20;
  width: 100%;
  height: auto;
  max-height: 1080px;
  display: grid;
  grid-template:
    'title' 1fr
    'message' 1fr
    'buttons' auto;
  gap: 16px 0;
  justify-items: center;
  padding: 40px;
  margin: 0 auto;
`

const LabelItem = styled.div`
  color: var(--on-background);
  text-align: center;
`
const Title = styled(LabelItem)`
  grid-area: title;
`
const Message = styled(LabelItem)`
  grid-area: message;
`
const Buttons = styled.div`
  grid-area: buttons;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  gap: 16px;
  ${breakpoints.up('lg')} {
    grid-template-columns: repeat(auto-fill, 1fr);
    grid-template-rows: auto;
  }
`

const VideoErrorView = ({ error }: { error: ErrorDetails }) => {
  const userError = useUserError({ scope: 'video', rawError: error })
  if (!userError) return <></>
  return (
    <Wrapper data-theme="dark">
      <Overlay />
      <Foreground>
        <Title>
          <HeadingLabel fontSize="5">{userError.title}</HeadingLabel>
        </Title>
        <Message>
          <SubheadingLabel fontSize="4" dangerouslySetInnerHTML={{ __html: userError.message }} />
        </Message>
        <Buttons>
          {userError.buttons.map((button) => {
            return (
              <GrandstandButton
                key={button.title}
                buttonSize={button.size}
                buttonStyle={button.style}
                onClick={(_) => {
                  button.action()
                }}
              >
                {button.title.toUpperCase()}
              </GrandstandButton>
            )
          })}
        </Buttons>
      </Foreground>
    </Wrapper>
  )
}

export default VideoErrorView
